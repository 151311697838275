import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Layout from '../components/layout';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/services-bio-hero.jpg';
import { Container, Row, Col } from 'react-grid-system';
import TwoCol from '../components/rows/twocol/index';
import Accordion from '../components/elements/accordion';
import Cta from '../components/rows/cta';

export default function Myant({ data }) {
  return (
    <Layout>
      <Seo
        title="Myant - Biosensing Garments"
        description="PACE is excited to partner with Myant/Skiin for this innovative new 
                biosensing undergarment study. Learn how PACE patients can take part in 
                this research program."
      />
      <Hero title="Myant - Biosensing Garments" service banner={Banner} />
      <Container>
      <div className="myant-out">
        {data.allStrapiMyant.edges.map(({ node }) => (
          <React.Fragment key={node}>
            <TwoCol
              title={node.myant_twocol.title}
              description={node.myant_twocol.description}
              newTab
              link={node.myant_twocol.button_title}
              href={node.myant_twocol.button_link}
              image={node.myant_twocol.image.localFile.publicURL}
            />
          </React.Fragment>
        ))}
        </div>
      </Container>
      {/* Let us hide this section for now */}
      {/* <div className="bg-gray-100 py-52 my-56 medical-bg">
        <Container>
          <Row>
            <Col md={6} sm={12} className="mx-auto">
              <h2 className="text-center">
                <span className="text-red-300">About</span> the Program
              </h2>
              <p>
                With the healthcare systems around the world under incredible
                strain, we require continuous, personalized, and preventative
                tools that revolutionize how we receive and distribute care.
              </p>
            </Col>
          </Row>
          {data.allStrapiMyant.edges.map(({ node }) => (
            <React.Fragment key={node}>
              <Accordion data={node.myant_accordion} />
            </React.Fragment>
          ))}
        </Container>
      </div> */}
        <Cta />
      <Container>
        <div className="myant-out">
          {data.allStrapiMyant.edges.map(({ node }) => (
            <React.Fragment key={node}>
              <TwoCol
                title={node.myant_twocol3.title}
                description={node.myant_twocol3.description}
                newTab
                link={node.myant_twocol3.button_title}
                href={node.myant_twocol3.button_link}
                image={node.myant_twocol3.image.localFile.publicURL}
              />
            </React.Fragment>
          ))}
        </div>
      </Container>
      {/* <Container>
        <div className="myant-out">
          {data.allStrapiMyant.edges.map(({ node }) => (
            <React.Fragment key={node}>
              <TwoCol
                reverse
                title={node.myant_twocol2.title}
                description={node.myant_twocol2.description}
                link={node.myant_twocol2.button_title}
                href={node.myant_twocol2.button_link}
                image={node.myant_twocol2.image.localFile.publicURL}
              />
            </React.Fragment>
          ))}
        </div>
      </Container> */}
      {/* <Cta /> */}
      <div className="mb-24 md:mb-52" />
    </Layout>
  );
}

export const query = graphql`
  query Myant {
    allStrapiMyant {
      edges {
        node {
          id # id of the node
          myant_twocol {
            id
            title
            description
            button_link
            button_title
            image {
              localFile {
                publicURL
              }
            }
          }
          myant_twocol2 {
            id
            title
            description
            button_link
            button_title
            image {
              localFile {
                publicURL
              }
            }
          }
          myant_accordion {
            id
            title
            description
          }
          myant_twocol3 {
            id
            title
            description
            button_link
            button_title
            image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`;
